#porfolio {
    height: 100vh;
    width: 100vw;
    margin-top: 2rem;
    @media only screen and (max-width: 600px) {
        height: 100%;
    }
}

.portfolio-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    // background-image: linear-gradient(rgb(149, 149, 149), rgb(255, 255, 255));
    background-color: white;
    padding: 1rem;
    .portfolio-title {
        margin-top: 2rem;

    }
    .portfolio-title > h1{
        font-family: 'Lato', sans-serif;
        font-size: 4.5em;
        font-weight: 700;
        text-align: center;
        background: linear-gradient(180deg, black 21.9%, #000000 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
        text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
        margin-bottom: 1rem;
        // text-shadow: 2px 2px 8px #000000;
    }

    .portfolio-tagline > h2{
        font-family: 'Lato', sans-serif;
        font-size: 3.5em;
        text-align: center;
        padding: 1rem;
        // background-image: linear-gradient(to left, #ffffff, #ffe0e0);
        background: linear-gradient(180deg, #D10000 21.9%, #000000 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
        text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.3);
        margin-bottom: 2rem;
    }

    .portfolio-companies-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        gap: 1rem;
        margin-bottom: 3rem;

        & > div {
            padding: 1rem;
        }

        & > div > img {
            width: 25rem;
        }

        #ashley, #costco {
            background-color: white;
        }
    }

    @media only screen and (max-width: 600px) {
        height: 100%;
        padding: 1rem;
        .portfolio-title>h1 {
            font-size: 3.5em;
        }
        .portfolio-tagline>h2 {
            font-size: 2.5em;
        }

        .portfolio-companies-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;
            &>div {
                padding: 0;
            }
            &>div>img {
                width: 20rem;
                object-fit: fill;
            }
        }
    }
}