#solutions {
    width: 100vw;
    padding-top: 2rem;
}

#hand-light-bulb {
    position: absolute;
    top: 0;
    right: 20;
    width: 25%;
    height: 100%;
    // background-color: red;
    #light-off {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;
        opacity: 1;
        transition: .5s;
        background-color: rgba(208, 221, 230, 0.79);
    }
}

#hand-light-bulb2 {
    position: absolute;
    top: 0;
    right: 20;
    width: 25%;
    height: 100%;
    z-index: -1;
    // background-color: red;
    #light-on {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;
        opacity: 0;
        transition: .5s;
        background-color: rgba(208, 221, 230, 0.79);
    }
}

.wwd-title > h1 {
    font-family: 'Lato', sans-serif;
    font-size: 4.5em;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(180deg, #303030 21.9%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
}

.wwd-items-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5rem;
    width: 100%;
    padding-left: 5rem;

    .wwd-item {
        position: relative;
        height: 15rem;
        width: 100%;
        .wwd-icon {
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            background-color: rgb(0, 0, 0);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
            z-index: 2;
        
            &>i {
                color: rgb(255, 255, 255);
                font-size: 2.5rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
        }
        .banner-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            .banner {
                padding: 1rem;
                padding-left: 5rem;
                height: 18rem;
                width: 25%;
                background-image: linear-gradient(to left, rgb(201, 201, 201), rgb(109, 109, 109));
                clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
                transition: .4s;
                &>p {
                    color: white;
                    opacity: 0;
                    transition: .7s;
                    width: 80%;
                    height: 100%;
                    font-size: 1.5em;
                    vertical-align: middle;
                    &:hover {
                        opacity: 1;
                    }
                }
                &:hover {
                    width: 56rem;
                }
            }
        }

    }
}

@media only screen and (max-width: 600px) {
    .wwd-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .wwd-title > h1 {
            padding: .5rem;
            font-size: 3.25em;
        }

        .wwd-items-container {
            width: 100%;
            margin: 0;
            padding-left: 0;

            .wwd-item {
                width: 100%;
                height: 100%;
            }
        }

        #hand-light-bulb {
            display: none;
        }
        #hand-light-bulb2 {
            display: none;
        }

        .wwd-icon {
            display: none;
        }

        .banner-container {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            width: 100%;
            .banner {
                padding: 0;
                &>p {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }
    .wwd-items-container .wwd-item .banner-container .banner {
        width: 100%;
        height: 100%;
        
        &>p {
            opacity: 1;
            font-size: 1.1em;
        }
    }
    
}