@import "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lato&family=Lora&display=swap";
html {
  scroll-behavior: smooth;
  font-family: Lora, serif;
  line-height: 1.15;
  overflow: auto;
}

body {
  margin: 0;
  overflow: auto;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px solid ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.draw-border {
  color: #fff;
  transition: color .25s 83.3333ms;
  position: relative;
  box-shadow: inset 0 0 0 4px #fff;
}

.draw-border:before, .draw-border:after {
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  width: 0;
  height: 0;
  border: 0 solid #0000;
  position: absolute;
  bottom: 0;
  right: 0;
}

.draw-border:before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.draw-border:after {
  border-top-width: 4px;
  border-right-width: 4px;
}

.draw-border:hover {
  color: #000;
}

.draw-border:hover:before, .draw-border:hover:after {
  width: 100%;
  height: 100%;
  border-color: #000;
  transition: border-color, width .25s, height .25s;
}

.draw-border:hover:before {
  transition-delay: 0s, 0s, .25s;
}

.draw-border:hover:after {
  transition-delay: 0s, .25s, 0s;
}

.draw-border-white {
  color: #000;
  transition: color .25s 83.3333ms;
  position: relative;
  box-shadow: inset 0 0 0 4px #000;
}

.draw-border-white:before, .draw-border-white:after {
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  width: 0;
  height: 0;
  border: 0 solid #0000;
  position: absolute;
  bottom: 0;
  right: 0;
}

.draw-border-white:before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.draw-border-white:after {
  border-top-width: 4px;
  border-right-width: 4px;
}

.draw-border-white:hover {
  color: #fff;
}

.draw-border-white:hover:before, .draw-border-white:hover:after {
  width: 100%;
  height: 100%;
  border-color: #fff;
  transition: border-color, width .25s, height .25s;
}

.draw-border-white:hover:before {
  transition-delay: 0s, 0s, .25s;
}

.draw-border-white:hover:after {
  transition-delay: 0s, .25s, 0s;
}

@media only screen and (max-width: 600px) {
  .draw-border-white {
    color: #fff;
    transition: color .25s 83.3333ms;
    position: relative;
    box-shadow: inset 0 0 0 4px #fff;
  }

  .draw-border-white:before, .draw-border-white:after {
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    width: 0;
    height: 0;
    border: 0 solid #0000;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .draw-border-white:before {
    border-bottom-width: 4px;
    border-left-width: 4px;
  }

  .draw-border-white:after {
    border-top-width: 4px;
    border-right-width: 4px;
  }

  .draw-border-white:hover {
    color: silver;
  }

  .draw-border-white:hover:before, .draw-border-white:hover:after {
    width: 100%;
    height: 100%;
    border-color: silver;
    transition: border-color, width .25s, height .25s;
  }

  .draw-border-white:hover:before {
    transition-delay: 0s, 0s, .25s;
  }

  .draw-border-white:hover:after {
    transition-delay: 0s, .25s, 0s;
  }
}

.draw-border-black-red {
  color: silver;
  transition: color .25s 83.3333ms;
  position: relative;
  box-shadow: inset 0 0 0 4px silver;
}

.draw-border-black-red:before, .draw-border-black-red:after {
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  width: 0;
  height: 0;
  border: 0 solid #0000;
  position: absolute;
  bottom: 0;
  right: 0;
}

.draw-border-black-red:before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.draw-border-black-red:after {
  border-top-width: 4px;
  border-right-width: 4px;
}

.draw-border-black-red:hover {
  color: #000;
}

.draw-border-black-red:hover:before, .draw-border-black-red:hover:after {
  width: 100%;
  height: 100%;
  border-color: #000;
  transition: border-color, width .25s, height .25s;
}

.draw-border-black-red:hover:before {
  transition-delay: 0s, 0s, .25s;
}

.draw-border-black-red:hover:after {
  transition-delay: 0s, .25s, 0s;
}

.draw-border-white-red {
  color: #fff;
  transition: color .25s 83.3333ms;
  position: relative;
  box-shadow: inset 0 0 0 4px #fff;
}

.draw-border-white-red:before, .draw-border-white-red:after {
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  width: 0;
  height: 0;
  border: 0 solid #0000;
  position: absolute;
  bottom: 0;
  right: 0;
}

.draw-border-white-red:before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.draw-border-white-red:after {
  border-top-width: 4px;
  border-right-width: 4px;
}

.draw-border-white-red:hover {
  color: #000;
}

.draw-border-white-red:hover:before, .draw-border-white-red:hover:after {
  width: 100%;
  height: 100%;
  border-color: #000;
  transition: border-color, width .25s, height .25s;
}

.draw-border-white-red:hover:before {
  transition-delay: 0s, 0s, .25s;
}

.draw-border-white-red:hover:after {
  transition-delay: 0s, .25s, 0s;
}

.btn {
  cursor: pointer;
  letter-spacing: .05rem;
  background: none;
  border: none;
  padding: 1em 2em;
  font: 700 1.5em Roboto Slab, sans-serif;
}

.btn:focus {
  outline: 2px dotted #55d7dc;
}

.animate-character {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg, #fff 0%, red 50%, #fff 100%);
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 190px;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

.fadeInTextColor {
  animation: fadeInColor 5s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200%;
  }
}

@keyframes fadeInColor {
  0% {
    color: #fff;
  }

  50% {
    color: red;
  }

  100% {
    color: #fff;
  }
}

#about-us {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  display: flex;
}

.about-us-container {
  width: 80%;
  height: 80%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fff;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  margin: auto;
  display: grid;
}

.about-us-container .box {
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.about-us-container .box-image-vision {
  background-image: url("vision_sunsetroad.5b1bf69c.jpg");
  background-size: cover;
}

.about-us-container .box-compass-vision {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.about-us-container .box-compass-vision > h3 {
  color: #000;
  z-index: 2;
  background: linear-gradient(#303030 21.9%, #000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 4px #0000004d;
  z-index: 2;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3em;
}

.about-us-container .box-compass-vision > p {
  color: #000;
  z-index: 2;
  font-size: 2em;
}

.about-us-container .box-compass-vision:before {
  content: "";
  opacity: .35;
  background-image: url("vision_compass.b10e066a.png");
  background-size: cover;
  position: absolute;
  inset: 0;
}

.about-us-container .box-image-mission {
  background-image: url("mission_handshake.97344be2.jpg");
  background-size: cover;
}

.about-us-container .box-compass-mission {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.about-us-container .box-compass-mission > h3 {
  color: #000;
  z-index: 2;
  background: linear-gradient(#000 21.9% 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 4px #0000004d;
  z-index: 2;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3em;
}

.about-us-container .box-compass-mission > p {
  color: #000;
  z-index: 2;
  font-size: 2em;
}

.about-us-container .box-compass-mission:before {
  content: "";
  opacity: .5;
  z-index: 1;
  background-image: url("mission_compass.8e963c4d.jpg");
  background-size: cover;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 600px) {
  #about-us {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  #about-us .wwd-title > h1 {
    margin: 0 0 2rem;
    font-size: 3em;
  }

  #about-us .about-us-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  #about-us .about-us-container .box-image-mission, #about-us .about-us-container .box-image-vision {
    display: none;
  }
}

#solutions {
  width: 100vw;
  padding-top: 2rem;
}

#hand-light-bulb {
  width: 25%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 20px;
}

#hand-light-bulb #light-off {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
  opacity: 1;
  background-color: #d0dde6c9;
  transition: all .5s;
}

#hand-light-bulb2 {
  width: 25%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 20px;
}

#hand-light-bulb2 #light-on {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
  opacity: 0;
  background-color: #d0dde6c9;
  transition: all .5s;
}

.wwd-title > h1 {
  text-align: center;
  background: linear-gradient(#303030 21.9%, #000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 4px #0000004d;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-size: 4.5em;
  font-weight: 700;
}

.wwd-items-container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5rem;
  padding-left: 5rem;
  display: flex;
  position: relative;
}

.wwd-items-container .wwd-item {
  height: 15rem;
  width: 100%;
  position: relative;
}

.wwd-items-container .wwd-item .wwd-icon {
  width: 6rem;
  height: 6rem;
  z-index: 2;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.wwd-items-container .wwd-item .wwd-icon > i {
  color: #fff;
  z-index: 2;
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wwd-items-container .wwd-item .banner-container {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.wwd-items-container .wwd-item .banner-container .banner {
  height: 18rem;
  width: 25%;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  background-image: linear-gradient(to left, #c9c9c9, #6d6d6d);
  padding: 1rem 1rem 1rem 5rem;
  transition: all .4s;
}

.wwd-items-container .wwd-item .banner-container .banner > p {
  color: #fff;
  opacity: 0;
  width: 80%;
  height: 100%;
  vertical-align: middle;
  font-size: 1.5em;
  transition: all .7s;
}

.wwd-items-container .wwd-item .banner-container .banner > p:hover {
  opacity: 1;
}

.wwd-items-container .wwd-item .banner-container .banner:hover {
  width: 56rem;
}

@media only screen and (max-width: 600px) {
  .wwd-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .wwd-container .wwd-title > h1 {
    padding: .5rem;
    font-size: 3.25em;
  }

  .wwd-container .wwd-items-container {
    width: 100%;
    margin: 0;
    padding-left: 0;
  }

  .wwd-container .wwd-items-container .wwd-item {
    width: 100%;
    height: 100%;
  }

  .wwd-container #hand-light-bulb, .wwd-container #hand-light-bulb2, .wwd-container .wwd-icon {
    display: none;
  }

  .wwd-container .banner-container {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .wwd-container .banner-container .banner {
    padding: 0;
  }

  .wwd-container .banner-container .banner > p {
    width: 100%;
    opacity: 1;
  }

  .wwd-items-container .wwd-item .banner-container .banner {
    width: 100%;
    height: 100%;
  }

  .wwd-items-container .wwd-item .banner-container .banner > p {
    opacity: 1;
    font-size: 1.1em;
  }
}

#porfolio {
  height: 100vh;
  width: 100vw;
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
  #porfolio {
    height: 100%;
  }
}

.portfolio-container {
  width: 100vw;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.portfolio-container .portfolio-title {
  margin-top: 2rem;
}

.portfolio-container .portfolio-title > h1 {
  text-align: center;
  background: linear-gradient(#000 21.9% 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 4px #0000004d;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 1rem;
  font-family: Lato, sans-serif;
  font-size: 4.5em;
  font-weight: 700;
}

.portfolio-container .portfolio-tagline > h2 {
  text-align: center;
  background: linear-gradient(#d10000 21.9%, #000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 5px 6px 4px #0000004d;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 2rem;
  padding: 1rem;
  font-family: Lato, sans-serif;
  font-size: 3.5em;
}

.portfolio-container .portfolio-companies-container {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
  display: flex;
}

.portfolio-container .portfolio-companies-container > div {
  padding: 1rem;
}

.portfolio-container .portfolio-companies-container > div > img {
  width: 25rem;
}

.portfolio-container .portfolio-companies-container #ashley, .portfolio-container .portfolio-companies-container #costco {
  background-color: #fff;
}

@media only screen and (max-width: 600px) {
  .portfolio-container {
    height: 100%;
    padding: 1rem;
  }

  .portfolio-container .portfolio-title > h1 {
    font-size: 3.5em;
  }

  .portfolio-container .portfolio-tagline > h2 {
    font-size: 2.5em;
  }

  .portfolio-container .portfolio-companies-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    display: flex;
  }

  .portfolio-container .portfolio-companies-container > div {
    padding: 0;
  }

  .portfolio-container .portfolio-companies-container > div > img {
    width: 20rem;
    object-fit: fill;
  }
}

#careers {
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 600px) {
  #careers {
    height: 100%;
  }
}

.careers-container {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.careers-container .join > h1 {
  text-align: center;
  color: #0000;
  background-image: linear-gradient(#000, #000);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: 1rem;
  margin-bottom: 0;
  font-size: 7em;
  font-weight: 700;
}

.careers-container .careers-tagline > h2 {
  text-align: center;
  color: #000;
  letter-spacing: .9rem;
  font-family: Lato, sans-serif;
  font-size: 4em;
  font-weight: 700;
}

.careers-container .careers-option-container {
  width: 100%;
  height: 40%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.careers-container .careers-option-container .join-ost {
  height: 100%;
  width: 40%;
  background: linear-gradient(to left, #c9c9c9, #6d6d6d);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.careers-container .careers-option-container .join-ost:after {
  content: "";
}

.careers-container .careers-option-container .join-ost > h1 {
  text-align: center;
  color: #fff;
  letter-spacing: .7rem;
  text-shadow: 2px 2px 5px #000;
  z-index: 2;
  margin: 0;
  font-family: Lato, sans-serif;
  font-size: 3.25em;
  font-weight: 500;
  line-height: 1.5em;
}

.careers-container .careers-option-container .join-ost .careers-spacer {
  height: 1rem;
}

.careers-container .careers-option-container .join-ost .careers-spacer .owner-spacer {
  height: .1rem;
}

.careers-container .careers-option-container .join-ost #job-btn {
  z-index: 5;
}

.careers-container .careers-option-container .owner-operators {
  height: 100%;
  width: 40%;
  background: linear-gradient(to right, #c9c9c9, #6d6d6d);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.careers-container .careers-option-container .owner-operators:after {
  content: "";
}

.careers-container .careers-option-container .owner-operators > h1 {
  text-align: center;
  color: #fff;
  letter-spacing: .7rem;
  text-shadow: 2px 2px 5px #000;
  z-index: 2;
  margin: 0;
  font-family: Lato, sans-serif;
  font-size: 3.5em;
  font-weight: 500;
  line-height: 1.5em;
}

.careers-container .careers-option-container .owner-operators #owner-btn {
  z-index: 5;
}

@media only screen and (max-width: 600px) {
  .careers-container {
    height: 100%;
  }

  .careers-container .join {
    margin-top: 2rem;
    padding: 1rem;
  }

  .careers-container .join > h1 {
    font-size: 3.5em;
    font-weight: 300;
  }

  .careers-container .careers-tagline > h2 {
    padding: 1rem;
    font-size: 2.25em;
  }

  .careers-container .careers-option-container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .careers-container .careers-option-container .join-ost {
    width: 90%;
    height: 100%;
  }

  .careers-container .careers-option-container .join-ost > h1 {
    margin-bottom: 1rem;
    font-size: 2.5em;
  }

  .careers-container .careers-option-container .join-ost:after {
    content: "";
    background: none;
  }

  .careers-container .careers-option-container .owner-operators {
    width: 90%;
    height: 100%;
  }

  .careers-container .careers-option-container .owner-operators > h1 {
    margin-bottom: 1rem;
    font-size: 2em;
  }

  .careers-container .careers-option-container .owner-operators:after {
    content: "";
    background: none;
  }
}

#contact-us {
  height: 100vh;
  width: 100vw;
  background-image: url("contact-us.d29dbd05.jpeg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  #contact-us {
    height: 100%;
    background-image: linear-gradient(#fff, #4f4e4e);
  }
}

.contact-container {
  height: 100vh;
  width: 50vw;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
}

.contact-container .contact-info {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
}

.contact-container .contact-info > h1 {
  text-align: center;
  color: #0000;
  background-image: linear-gradient(#000, #000);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: .5rem;
  padding: 1rem;
  font-size: 4.5em;
  font-weight: 500;
}

.contact-container .contact-info .card {
  padding: 1rem 1rem 1rem 7rem;
  position: relative;
}

.contact-container .contact-info .card > i {
  color: #000;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

@media only screen and (max-width: 600px) {
  .contact-container {
    width: 100%;
  }

  .contact-container .contact-info {
    gap: 1rem;
  }

  .contact-container .contact-info > h1 {
    font-size: 3.25em;
  }

  .contact-container .contact-info h2 {
    font-size: 1.25em;
  }

  .contact-container .contact-info .card {
    padding-left: 5rem;
  }

  .contact-container .contact-info .card > i {
    font-size: 3rem;
  }

  .contact-container .contact-info #days-time {
    font-size: .9em;
  }

  .contact-container .contact-info #contact-email {
    font-size: 1.1em;
  }
}

#mobile-nav-modal {
  display: none;
}

#navbar {
  opacity: 1;
  transition: opacity .5s;
}

#nav-menu, #nav-modal-layer {
  display: none;
}

.nav-container {
  width: 100%;
  background-color: #d5d5d580;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@keyframes showNavBg {
  from {
    background-color: #0000;
  }

  to {
    background-color: #fff;
  }
}

@keyframes hideNavBg {
  from {
    background-color: #fff;
  }

  to {
    background-color: #0000;
  }
}

.ost-nav {
  min-width: 50%;
  z-index: 10;
  justify-content: flex-end;
  align-items: center;
  margin-right: .5rem;
  padding: .8rem 1rem;
  display: flex;
}

.ost-nav .ost-nav-item {
  padding: .8rem 1rem;
  position: relative;
}

.ost-nav .ost-nav-item a:before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(2);
}

.ost-nav .ost-nav-item a:hover:before {
  opacity: 1;
  transform: scale(1);
}

.ost-nav .ost-nav-item a:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -2;
  background: silver;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.ost-nav .ost-nav-item a:hover {
  color: #000;
}

.ost-nav .ost-nav-item a:hover:after {
  opacity: 1;
  transform: scale(1);
}

.ost-nav .ost-nav-item a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
  transition: font-weight .3s;
}

.ost-nav .ost-nav-item a.active {
  color: silver;
  font-weight: bold;
}

.ost-nav .ost-nav-item a.active:hover {
  color: #000;
}

.ost-nav .ost-nav-item.active {
  border-bottom: 2px solid #000;
}

.sticky {
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 600px) {
  #nav-modal-content {
    display: block;
  }

  #navbar {
    background-color: #0000;
  }

  #nav-logo, .ost-nav {
    display: none;
  }

  #nav-modal-layer {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9000;
    background-color: #64646493;
    transition: opacity .3s;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  #nav-menu {
    color: #303030;
    cursor: pointer;
    margin: 1rem;
    text-decoration: none;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }

  #nav-menu > i {
    font-size: 2rem;
  }

  #mobile-nav-modal {
    z-index: 9999;
    width: 80%;
    height: 100%;
    background-color: #202020e6;
    transition: transform .4s ease-in;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translateX(-100%);
  }

  #mobile-nav-modal .nav-modal-content {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    display: flex;
  }

  #mobile-nav-modal .nav-modal-content #nav-modal-logo {
    margin-top: 1rem;
  }

  #mobile-nav-modal .nav-modal-content .nav-items {
    width: 100%;
    cursor: pointer;
    margin-top: 1.5rem;
  }

  #mobile-nav-modal .nav-modal-content .nav-items .ost-nav-item {
    width: 100%;
    border: 1px solid silver;
    border-radius: .5rem;
    margin-top: .5rem;
    padding: 1rem;
  }

  #mobile-nav-modal .nav-modal-content .nav-items .ost-nav-item > a {
    color: #fff;
    text-decoration: none;
  }

  #mobile-nav-modal .nav-modal-content .nav-items .active {
    background-color: silver;
    border: 2px solid #fff;
  }

  #mobile-nav-modal .nav-modal-content .nav-items .active > a {
    color: #000;
  }
}

body {
  background-color: #fff;
}

.logo {
  width: 10rem;
  height: 3rem;
}

#main {
  position: relative;
}

.main-container1 {
  height: 45vh;
  margin-top: 3rem;
  position: relative;
}

.main-container2 {
  width: 100%;
  height: 40vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  display: flex;
}

.main-container2 .main-about {
  max-width: 900px;
  width: 85%;
  text-align: center;
  margin-bottom: 1rem;
  padding: 1rem;
}

.main-container2 .main-about > p {
  text-align: center;
  color: #000;
  font-size: 1.75em;
  line-height: 200%;
}

@media only screen and (max-width: 600px) {
  .main-container2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .main-container2 .main-about > p {
    font-size: 1.15em;
  }
}

.main-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-container .logo-block {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.main-container .logo-block:before {
  content: "";
  z-index: 2;
  background-color: silver;
  animation: mainBlock 1.5s cubic-bezier(.74, .06, .4, .92) forwards;
  position: absolute;
  inset: 0;
}

.main-container #ost-logo {
  width: 25rem;
  opacity: 0;
  margin-bottom: 2rem;
  animation: mainFadeIn 2s .5s forwards;
}

.main-container .tagline {
  color: red;
  text-shadow: 5px 5px 5px #000;
  width: 75%;
  text-align: center;
  opacity: 0;
  font-family: Lora, sans-serif;
  font-size: 4rem;
  font-weight: 600;
  animation: mainFadeIn 3.5s 1s forwards;
}

.main-container .tag-block {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.main-container .tag-block:before {
  content: "";
  z-index: 2;
  background-color: #2b2b2b;
  animation: secBlock 2.5s cubic-bezier(.74, .06, .4, .91) forwards;
  position: absolute;
  inset: 0;
}

.main-container #about-us {
  width: 100vw;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .main-container {
    width: 100vw;
  }

  .main-container #ost-logo {
    width: 15rem;
  }

  .main-container .tagline {
    font-size: 3rem;
  }

  #learn {
    display: none;
  }
}

#landing-video {
  width: 100%;
  height: 100%;
  z-index: -5;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.video-overlay {
  width: 100%;
  height: 100%;
  z-index: -4;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.card {
  border-radius: 1rem;
  transition: all .3s;
  box-shadow: 0 4px 8px #0003;
}

.card:hover {
  box-shadow: 0 16px 32px #0000004d;
}

#created {
  color: #0000;
  background-color: #0000;
  position: fixed;
  bottom: 0;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    background: #e9d856;
    border: 0 solid #ddd;
  }

  50% {
    width: 10px;
    height: 10px;
    opacity: 1;
    background: #e9d856;
    bottom: 45px;
  }

  65% {
    width: 7px;
    height: 7px;
    width: 15px;
    bottom: 0;
  }

  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }

  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0 solid #222;
    bottom: 13px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .5;
  }
}

@keyframes hideLogo {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes showLogo {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes showNavbarLogo {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hideNavbarLogo {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/*# sourceMappingURL=index.ab6d07ef.css.map */
