
@use 'scss_includes/_reset';
@use 'scss_includes/_cta-button';
@use 'scss_includes/_about-us';
@use 'scss_includes/_what-we-do';
@use 'scss_includes/_portfolio';
@use 'scss_includes/_careers';
@use 'scss_includes/_contact-us';
@use 'scss_includes/_nav.scss';


body {
    background-color: rgb(255, 255, 255);
}

.logo {
    width: 10rem;
    height: 3rem;
}

#main {
    position: relative;
}

.main-container1 {
    position: relative;
    height: 45vh;
    margin-top: 3rem;
}
.main-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;
    margin-bottom: 3rem;
    .main-about {
        max-width: 900px;
        width: 85%;
        padding: 1rem;
        text-align: center;
        margin-bottom: 1rem;
        // background: linear-gradient(to left, rgb(201, 201, 201), rgb(109, 109, 109));
        &>p {
            font-size: 1.75em;
            text-align: center;
            color: rgb(0, 0, 0);
            line-height: 200%;
            // text-shadow: 2px 2px 2px #000000;
        }
    }

    @media only screen and (max-width: 600px) {
        margin-top: 2rem;
        margin-bottom: 2rem;
        .main-about {
            &>p {
                font-size: 1.15em;
            }
        }
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .logo-block {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        

        &::before {
            content: '';
            background-color: silver;
            position: absolute;
            top: 0;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 2;
            animation: mainBlock 1.5s cubic-bezier(.74, .06, .4, .92) forwards;
        }
    }

    #ost-logo {
        width: 25rem;
        opacity: 0;
        margin-bottom: 2rem;
        -webkit-animation: mainFadeIn 2s forwards;
        -o-animation: mainFadeIn 2s forwards;
        animation: mainFadeIn 2s forwards;
        animation-delay: .5s;
    }
    
    .tagline {
        color: rgb(255, 0, 0);
        font-size: 4rem;
        font-weight: 600;
        font-family: 'Lora', sans-serif;
        text-shadow: 5px 5px 5px #000000;
        width: 75%;
        text-align: center;
        opacity: 0;
        -webkit-animation: mainFadeIn 3.5s forwards;
        -o-animation: mainFadeIn 3.5s forwards;
        animation: mainFadeIn 3.5s forwards;
        animation-delay: 1s;
    }

    .tag-block {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &::before {
            content: '';
            background-color: rgb(43, 43, 43);
            position: absolute;
            top: 0;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 2;
            animation: secBlock 2.5s cubic-bezier(.74, .06, .4, .91) forwards;
        }
    }

    #about-us {
        width: 100vw;
        height: 100vh;
    }
}

@media only screen and (max-width: 600px) {
    .main-container {
        width: 100vw;
        #ost-logo {
            width: 15rem;
        }

        .tagline {
            font-size: 3rem;
        }
    }

    #learn {
        display: none;
    }
}

#landing-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -4;
}



.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 1rem;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.3);
}

#created {
    background-color: transparent;
    position: fixed;
    bottom: 0;
    color: transparent;
}

@keyframes mainBlock {
    0% {
        width: 0%;
        left: 0;

    }

    50% {
        width: 100%;
        left: 0;

    }

    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes secBlock {
    0% {
        width: 0%;
        left: 0;

    }

    50% {
        width: 100%;
        left: 0;

    }

    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes mainFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes popIn {
    0% {
        width: 0px;
        height: 0px;
        background: #e9d856;
        border: 0px solid #ddd;
        opacity: 0;
    }

    50% {
        width: 10px;
        height: 10px;
        background: #e9d856;
        opacity: 1;
        bottom: 45px;
    }

    65% {
        width: 7px;
        height: 7px;
        bottom: 0px;
        width: 15px
    }

    80% {
        width: 10px;
        height: 10px;
        bottom: 20px
    }

    100% {
        width: 7px;
        height: 7px;
        background: #e9d856;
        border: 0px solid #222;
        bottom: 13px;

    }
}

@keyframes secFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}


@keyframes hideLogo {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes showLogo {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes showNavbarLogo {
    from { opacity: 0 }
    to { opacity: 1 }
}

@keyframes hideNavbarLogo {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}