#careers {
    height: 100vh;
    width: 100vw;
    @media only screen and (max-width: 600px) {
        height: 100%;
    }
}

.careers-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100vw;
    // background-image: linear-gradient(to bottom, rgb(153, 153, 153), rgb(255, 255, 255));
    background-color: rgb(255, 255, 255);
    // background-image: url(images/joinus.jpg);
    // background-size: cover;
    
    .join {
        & > h1 {
            font-size: 7em;
            font-weight: 700;
            text-align: center;
            background-image: linear-gradient(to bottom, black, #000000);
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            letter-spacing: 1rem;
            margin-bottom: 0;
        }
    }

    .careers-tagline {
        & > h2 {
            font-family: 'Lato', sans-serif;
            font-size: 4em;
            font-weight: 700;
            text-align: center;
            color: rgb(0, 0, 0);
            letter-spacing: .9rem;
            // text-shadow: 4px 4px 4px #000000;
        }
    }

    .careers-option-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 40%;

        .join-ost {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: 100%;
            width: 40%;
            padding: 1rem;
            background: linear-gradient(to left, rgb(201, 201, 201), rgb(109, 109, 109));
            // background-color: rgb(255, 255, 255);

            &::after {
                content: '';
                // background-image: url(images/about_us.svg);
                // background-repeat: no-repeat;
                // background-position: center;
                // background-size: 60%;
                // position: absolute;
                // top: -5rem;
                // right: 0px;
                // bottom: 0px;
                // left: 0px;
                // opacity: .5;
            }

            & > h1 {
                font-family: 'Lato', sans-serif;
                font-size: 3.25em;
                font-weight: 500;
                text-align: center;
                color: rgb(255, 255, 255);
                letter-spacing: .7rem;
                text-shadow: 2px 2px 5px #000000;
                line-height: 1.5em;
                z-index: 2;
                margin: 0;
            }

            .careers-spacer {
                height: 1rem;
                .owner-spacer {
                    height: .1rem;
                }
            }

            #job-btn {
                z-index: 5;
            }
        }

        .owner-operators {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: 100%;
            width: 40%;
            padding: 1rem;
            background: linear-gradient(to right, rgb(201, 201, 201), rgb(109, 109, 109));
            // background-color: white;            
            &::after {
                content: '';
                // background-image: url(images/truck.svg);
                // background-repeat: no-repeat;
                // background-position: center;
                // background-size: 60%;
                // position: absolute;
                // top: 0;
                // right: 0px;
                // bottom: 0px;
                // left: 0px;
                // opacity: .5;
            }

            &>h1 {
                font-family: 'Lato', sans-serif;
                font-size: 3.5em;
                font-weight: 500;
                text-align: center;
                color: rgb(255, 255, 255);
                letter-spacing: .7rem;
                line-height: 1.5em;
                text-shadow: 2px 2px 5px #000000;
                z-index: 2;
                margin: 0;
            }

            #owner-btn {
                z-index: 5;
            }
        }
    }
    
    @media only screen and (max-width: 600px) {
        height: 100%;
        .join {
            margin-top: 2rem;
            padding: 1rem;
            &>h1 {
                font-size: 3.5em;
                font-weight: 300;
            }
        }

        .careers-tagline {
            &>h2 {
                font-size: 2.25em;
                padding: 1rem;
            }
        }

        .careers-option-container {
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            gap: 2rem;
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;

            .join-ost {
                width: 90%;
                height: 100%;
                &>h1 {
                    font-size: 2.5em;
                    margin-bottom: 1rem;
                }
                &::after {
                    content: '';
                    background: none;
                }
            }

            .owner-operators {
                width: 90%;
                height: 100%;

                &>h1 {
                    font-size: 2em;
                    margin-bottom: 1rem;
                }
                &::after {
                    content: '';
                    background: none;
                }
            }
        }
    }
}