#contact-us {
    height: 100vh;
    width: 100vw;
    background-image: url(images/contact-us.jpeg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    @media only screen and (max-width: 600px) {
        height: 100%;
        background-image: linear-gradient(rgb(255, 255, 255), rgb(79, 78, 78));
    }
}

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100vh;
    width: 50vw;
    padding: 2rem;

    .contact-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        width: 100%;

        &>h1 {
            font-size: 4.5em;
            font-weight: 500;
            text-align: center;
            background-image: linear-gradient(to bottom, black, #000000);
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            letter-spacing: .5rem;
            padding: 1rem;
        }

        .card {
            padding: 1rem;
            padding-left: 7rem;
            position: relative;
            &>i {
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);
                font-size: 4rem;
                color: black;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        
        .contact-info {
            gap: 1rem;
            &>h1 {
                font-size: 3.25em;
            }
            h2 {
                font-size: 1.25em;
            }
            .card {
                padding-left: 5rem;
                &>i {
                    font-size: 3rem;
                }
            }
            #days-time {
                font-size: .9em;
            }
            #contact-email {
                font-size: 1.10em;
            }
        }
    }
}