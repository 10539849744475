@mixin btn-border-drawing($color: silver, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
    box-shadow: inset 0 0 0 $width $color;
    color: $color;
    transition: color $duration calc($duration / 3);
    position: relative;

    &::before,
    &::after {
        border: 0 solid transparent;
        box-sizing: border-box;
        content: '';
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;

        #{$vertical}: 0;
        #{$horizontal}: 0;
    }

    &::before {
        $h-side: if($horizontal =='left', 'right', 'left');

        border-#{$vertical}-width: $width;
        border-#{$h-side}-width: $width;
    }

    &::after {
        $v-side: if($vertical =='top', 'bottom', 'top');

        border-#{$v-side}-width: $width;
        border-#{$horizontal}-width: $width;
    }

    &:hover {
        color: $hover;

        &::before,
        &::after {
            border-color: $hover;
            transition: border-color 0s, width $duration, height $duration;
            width: 100%;
            height: 100%;
        }

        &::before {
            transition-delay: 0s, 0s, $duration;
        }

        &::after {
            transition-delay: 0s, $duration, 0s;
        }
    }
}

.draw-border {
    @include btn-border-drawing(#ffffff, #000000, 4px, bottom, right);
}

.draw-border-white {
    @include btn-border-drawing(#000000, #ffffff, 4px, bottom, right);
    @media only screen and (max-width: 600px) {
        @include btn-border-drawing(#ffffff, #c0c0c0, 4px, bottom, right);
    }
}

.draw-border-black-red {
    @include btn-border-drawing(#c0c0c0, black, 4px, bottom, right);
}

.draw-border-white-red {
    @include btn-border-drawing(#ffffff, #000000, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.btn {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 700 1.5em 'Roboto Slab', sans-serif;
    padding: 1em 2em;
    letter-spacing: 0.05rem;

    &:focus {
        outline: 2px dotted #55d7dc;
    }
}

.animate-character {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #ffffff 0%,
            #ff0000 50%,
            #ffffff 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    // color: #fff;
    background-clip: text;
    // text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

.fadeInTextColor {
    animation: fadeInColor 5s linear infinite;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

@keyframes fadeInColor {
    0% {
        color: white;
    }
    50% {
        color: rgb(255, 0, 0);
    }
    100% {
        color: white;
    }
}