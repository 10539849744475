#mobile-nav-modal {
    display: none;
}

#navbar {
    opacity: 1;
    transition: opacity .5s;
}

#nav-menu {
    display: none;
}

#nav-modal-layer {
    display: none;
}

.nav-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(213, 213, 213, .5);
}

@keyframes showNavBg {
    from {
        background-color: transparent;
    }

    to {
        background-color: rgb(255, 255, 255);
    }
}

@keyframes hideNavBg {
    from {
        background-color: rgb(255, 255, 255);
    }

    to {
        background-color: transparent;
    }
}

.ost-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 50%;
    padding: .8rem 1rem;
    z-index: 10;
    margin-right: .5rem;

    .ost-nav-item {
        position: relative;
        padding: .8rem 1rem;

        a:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // border-top: 2px solid #606060;
            // border-bottom: 2px solid silver;
            transform: scale(2);
            transition: .3s;
            opacity: 0;
            z-index: -1;
        }

        a:hover:before {
            transform: scale(1);
            opacity: 1;
        }

        a:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: silver;
            transform: scale(0);
            transition: .4s;
            opacity: 0;
            z-index: -2;
        }

        a:hover {
            color: black;
        }

        a:hover:after {
            transform: scale(1);
            opacity: 1;
        }

        a {
            text-decoration: none;
            color: rgb(0, 0, 0);
            font-weight: 500;
            transition: font-weight .3s;
        }

        a.active {
            color: silver;
            font-weight: bold;
        }

        a.active:hover {
            color: black;
        }

        &.active {
            // border-top: 2px solid #606060;
            border-bottom: 2px solid black;
            // background: silver;
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

@media only screen and (max-width: 600px) {
    #nav-modal-content {
        display: block;
    }
    #navbar {
        background-color: transparent;
    }
    #nav-logo {
        display: none;
    }
    .ost-nav {
        display: none;
    }

    #nav-modal-layer {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity .3s;
        background-color: rgba(100, 100, 100, 0.578);
        z-index: 9000;
    }

    #nav-menu {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        margin: 1rem;
        text-decoration: none;
        color: #303030;
        cursor: pointer;
        &>i {
            font-size: 2rem;
        }
    }

    #mobile-nav-modal {
        display: block;
        position: fixed;
        /* Stay in place */
        z-index: 9999;
        /* Sit on top */
        left: 0;
        top: 0;
        width: 80%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: hidden;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(32, 32, 32, 0.9);
        /* Black w/ opacity */
        transform: translateX(-100%);
        transition: transform .4s ease-in;
        
        .nav-modal-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem;
            width: 100%;
            #nav-modal-logo {
                margin-top: 1rem;
            }
            .nav-items {
                width: 100%;
                margin-top: 1.5rem;
                cursor: pointer;
                .ost-nav-item {
                    margin-top: .5rem;
                    border: 1px solid silver;
                    border-radius: .5rem;
                    padding: 1rem;
                    width: 100%;
    
                    &>a {
                        text-decoration: none;
                        color: rgb(255, 255, 255);
                    }
                }
                .active {
                    background-color: silver;
                    border: 2px solid white;
                    &>a {
                        color: rgb(0, 0, 0);
                    }
                }
            }
        }
    }
}