#about-us {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

.about-us-container {
    width: 80%;
    height: 80%;
    margin: auto;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .box {
        // background-color: #333;
        padding: 20px;
        // border-radius: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-size: 40px;
    }

    .box-image-vision {
        background-image: url(images/vision_sunsetroad.jpg);
        background-size: cover;
    }
    .box-compass-vision {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        
        &>h3 {
            color: black;
            z-index: 2;
            font-size: 3em;
            background: linear-gradient(180deg, #303030 21.9%, #000000 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            // text-fill-color: transparent;
            text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
            z-index: 2;
        }
        &>p {
            color: black;
            z-index: 2;
            font-size: 2em;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
            background-image: url(images/vision_compass.png);
            background-size: cover;
        }
    }

    .box-image-mission {
        background-image: url(images/mission_handshake.jpg);
        background-size: cover;
    }
    .box-compass-mission {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    
        &>h3 {
            color: black;
            z-index: 2;
            font-size: 3em;
            background: linear-gradient(180deg, black 21.9%, black 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            // text-fill-color: transparent;
            text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
            z-index: 2;
        }
    
        &>p {
            color: black;
            z-index: 2;
            font-size: 2em;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.5;
            z-index: 1;
            background-image: url(images/mission_compass.jpg);
            background-size: cover;
        }
    }
}

@media only screen and (max-width: 600px) {
    #about-us {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;


        .wwd-title > h1 {
            font-size: 3em;
            margin: 0;
            margin-bottom: 2rem;
        }

        .about-us-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .box-image-mission {
                display: none;
            }

            .box-image-vision {
                display: none;
            }
        }
        
    }
}
